import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/compensationUserState/page',
        method: 'GET',
        params
    });
}
// 获取项目下拉数据
export function getProJson(params) {
    return request({
        url: '/admin/project/page',
        method: 'GET',
        params
    });
}
// 查看人员
export function UserDetail(params) {
    return request({
        url: '/admin/compensationUserState/pageCompensationUsers',
        method: 'GET',
        params
    });
}

// 上传回执
export function upload(data) {
    return request({
        url: '/admin/compensationUserState/returnReceiptImgUrl',
        method: 'POST',
        data
    });
}

// 锁定
export function Lock(data) {
    return request({
        url: '/admin/compensationUserState/upoadImgLock',
        method: 'POST',
        data
    });
}
