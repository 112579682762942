<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.upload_return_receipt_state"
            placeholder="请选择回执状态"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">请选择回执状态</a-select-option>
            <a-select-option value="1">未上传</a-select-option
            >
             <a-select-option value="2">已上传</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 350px"
            v-model="page.project_id"
            placeholder="请选择项目"
            show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">请选择项目</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="getList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
      <a-form-item>
         <a-button
            style="margin-right: 5px"
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/compensationUserStatePage', page)"
            >下载回执</a-button
          >
      </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
      >
        <template slot="state" slot-scope="text">
          <a-tag color="orange" v-if="text == 1"> 待审核 </a-tag>
          <a-tag color="green" v-if="text == 2"> 审核通过 </a-tag>
          <a-tag color="red" v-if="text == 3"> 审核拒绝 </a-tag>
        </template>
        <template slot="upload_return_receipt_state" slot-scope="text">
          <a-tag color="green" v-if="text == 2"> 已上传 </a-tag>
          <a-tag color="orange" v-if="text == 1"> 未上传 </a-tag>
        </template>

        <template slot="idno_positive_img_url" slot-scope="text">
          <view-file :data="text"></view-file>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button
            style="margin-right: 5px"
            size="small"
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/bankTable', { id: record.id })"
            >银行表</a-button
          >
          <a-button
            style="margin-right: 5px"
            size="small"
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="
              $exportFun('/export/individualIncomeTax', { id: record.id })
            "
            >综合所得申报</a-button
          >
          <a-button
            style="margin-right: 5px"
            size="small"
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="
              $exportFun('/export/personnelInformation', { id: record.id })
            "
            >人员信息采集导入模板</a-button
          >
          <a-button
            style="margin-right: 5px"
            size="small"
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="
              $exportFun('/export/normalWageIncome', { id: record.id })
            "
            >正常工资薪金所得表</a-button
          >
          <a-button size="small" icon="user" @click="() => openUserlist(record)"
            >查看详情</a-button
          >
          <a-button
            v-if="is_financial"
            size="small"
            icon="vertical-align-top"
            @click="() => UploadReceipt(record)"
            >上传回执</a-button
          >
         
          <a-button
            v-if="is_financial && record.is_lock == 1"
            size="small"
            icon="lock"
            @click="() => lock(record)"
            >锁定</a-button
          >
        </template>
      </a-table>
    </div>
    <div class="page-cu-pagination">
      <a-pagination
        :page-size.sync="page.pageSize"
        :total="page.totalRow"
        v-model="page.start"
        @change="changePage"
      />
    </div>
    <div class="page-cu-pagination"></div>
    <!-- 查看人员 -->
    <a-modal
      title="查看详情"
      width="1400px"
      :visible="vUserVisible"
      @ok="vUserVisible = false"
      ok-text="确认"
      cancel-text="取消"
      @cancel="vUserVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <div class="page-cu-top" style="padding-top: 0px">
        <a-form layout="inline">
          <a-form-item>
            <a-input
              v-model="vUserPage.key"
              placeholder="请输入关键字"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getUserDetail" icon="search"
              >搜索</a-button
            >
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="vUserColumns"
        :data-source="vUserList"
        :pagination="{
          total: vUserPage.totalRow,
          defaultPageSize: vUserPage.limit,
          defaultCurrent: 1,
        }"
        :scroll="{ x: 1000, y: 500 }"
        bordered
        rowKey="id"
      >
        <template slot="grant_year" slot-scope="text, record">
          {{ record.grant_year }}年{{ record.grant_month }}月
        </template>
      </a-table>
    </a-modal>

    <a-modal
      title="上传回执文件"
      v-if="uploadVisible"
      :visible="true"
      @ok="uploadData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="uploadVisible = false"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="uploadModel"
        :rules="uploadodelRules"
        :model="upload"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="附件" prop="img_url">
          <custom-file-list
            :img_url.sync="upload.return_receipt_img_url"
            :type="'multiple'"
            @changeCustomFile="changeImgUrl"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      columns: [
        {
          title: "项目名称",
          dataIndex: "pname",
        },
        {
          title: "劳务队",
          dataIndex: "tname",
        },
        {
          title: "发放总金额",
          dataIndex: "grant_fee",
        },
        {
          title: "签名",
          dataIndex: "img_url",
          key: "img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "提交时间",
          dataIndex: "create_time",
        },
        {
          title: "审核人",
          dataIndex: "cname",
          width: 100,
        },
        {
          title: "审核状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 100,
        },
        {
          title: "审核时间",
          dataIndex: "company_audit_time",
        },
        {
          title: "回执状态",
          dataIndex: "upload_return_receipt_state",
          scopedSlots: { customRender: "upload_return_receipt_state" },
          width: 100,
        },
        {
          title: "回执文件",
          dataIndex: "return_receipt_img_url",
          key: "return_receipt_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: 200,
        },
      ],
      uploadVisible: false,
      uploadodelRules: {
        return_receipt_img_url: [
          { required: true, message: "请上传回执文件", trigger: "change" },
        ],
      },
      isAdd: true, // TODO: 是否是新增操作
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        state: 2,
        key: "",
        project_id: "",
        upload_return_receipt_state:""
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      submitFlag: true,
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      proJson: [],
      vUserVisible: false,
      vUserPage: {
        start: 1,
        totalRow: 0,
        pageSize: 20,
        limit: 20,
        id: "",
        project_id: "",
        key: "",
      },
      vUserColumns: [
        {
          title: "姓名",
          dataIndex: "uname",
          width: 80,
        },
        {
          title: "身份证",
          dataIndex: "idno",
        },
        {
          title: "银行卡号",
          dataIndex: "bank_card",
        },
        {
          title: "工种",
          dataIndex: "wname",
          width: 80,
        },
        {
          title: "所属项目",
          dataIndex: "pname",
        },

        {
          title: "应发放费用",
          dataIndex: "grant_fee",
          width: 105,
        },
        {
          title: "个税金额",
          dataIndex: "personal_income_tax_fee",
          width: 100,
        },
        {
          title: "实际发放费用",
          dataIndex: "practical_grant_fee",
          width: 120,
        },
        {
          title: "工资年月",
          dataIndex: "grant_year",
          scopedSlots: { customRender: "grant_year" },
          width: 105,
        },
      ],
      vUserList: [],
      upload: {
        id: "",
        upload_return_receipt_state: 2,
        return_receipt_img_url: "",
      },
      is_financial:false,
      lockPage:{
        id:"",
        is_lock:2,
      }
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.getProJson();
    if(JSON.parse(window.localStorage.getItem("admin")).is_financial == "2"){
        this.is_financial = true;
    }else{
        this.is_financial = false;
    }
    console.log(this.is_financial)
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    // 查看审核数据下人员
    openUserlist(data) {
      this.vUserPage.project_id = data.project_id;
      this.vUserPage.id = data.id;
      this.vUserVisible = true;
      this.getUserDetail();
    },
    async getUserDetail() {
      let res = await Api.UserDetail({ ...this.vUserPage });
      if (res && res["code"] == "0") {
        this.vUserList = res.page.list;
      } else {
        this.vUserList = [];
        this.$message.error(res.message);
      }
    },
    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
   
    changeImgUrl(url) {
      this.upload.return_receipt_img_url = url.join(",");
    },
    UploadReceipt(data) {
      this.uploadVisible = true;
      this.upload.id = data.id;
      this.upload.return_receipt_img_url = data.return_receipt_img_url;
    },
    lock(row){
       let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "锁定之后将不能上传回执,您确定要锁定吗?",
        onOk() {
          that.lockPage.id = row.id; 
          that.Golock();
        },
        onCancel() {},
      });
    },
    async Golock() {
      let res = await Api.Lock({ ...this.lockPage });
      if (res && res["code"] == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.vUserList = [];
        this.$message.error(res.message);
      }
    },
    uploadData() {
      this.$refs.uploadModel.validate(async (valid) => {
        if (valid) {
          let res = await Api.upload({ ...this.upload });
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.uploadVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
